import React from 'react'


class Archive extends React.Component {
  render() {
    return (
    	<div id="history_pg" className="pg_padding">
    	    <div className="main_menu">
              <ul>
                <li>Initial Public Offering
                  <div className="right_menu">
                      <li><a href="#/">shop</a></li>
                      <li><a href="#/about">about</a></li>
                      <li><a href="#/archive">archive</a></li>
                  </div>
                </li>
              </ul>
            </div>
    		<div className="page_container">
    			<div className="history_content">
    				<p><strong>Distributed on August 11th, 2019:</strong><br/><i>Trevor 223</i>(42inx90in) by Michael Clark</p>
    				<img src="../assets/severed1.jpg"/>
    			</div>
          <div className="history_content">
          <p><strong>Distributed on November 25th, 2019:</strong><br/><i>Trevor 224</i>(42inx90in) by Michael Clark</p>
            <img src="../assets/severed_plain2.png"/>
          </div>
	    	</div>
    	</div>
    )
  }
}
export default Archive;