import React, { Component, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, browserHistory } from 'react-router'
import { withRouter, Link } from "react-router-dom";

const PAINTING_IMG=process.env.PUBLIC_URL + '/assets/severed1.jpg'
const heroku_api = 'https://ipo-paintings.herokuapp.com/media'
const proxyUrl = "https://guarded-everglades-26033.herokuapp.com/"
var selected_sku = "test"



class OrderList extends React.Component {
  handleClick(item) {
      var clicked_sku = item
      console.log("HOLA " + item)
  }
   render() {
    return (
            this.props.state.data.map(item => (
              <li key={item.section} className={item.stock}>Share {item.section}<span className="stock">{item.stock}</span>
                <Link
                  to={{
                    pathname: "/order",
                    data: item,
                    state: { fromDashboard: true }
                  }}>
                  <button className="purchase_btn" onClick={()=>{this.handleClick(item)}}>purchase</button>
                </Link>
              </li>
            ))
        );
    }
}


class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    }
  }
  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 10,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;
    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;

    return (
      <div className="Countdown">
        <span className="Countdown-col">
          <span className="Countdown-col-element">
              <strong>{this.addLeadingZeros(countDown.days)}</strong>
              <span> {countDown.days === 1 ? 'Day' : 'days'}</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong> {this.addLeadingZeros(countDown.hours)}</strong>
            <span> hours</span>
          </span>
        </span>


        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong> {this.addLeadingZeros(countDown.min)}</strong>
            <span> minutes</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong> {this.addLeadingZeros(countDown.sec)}</strong>
            <span> seconds</span>
          </span>
        </span>
      </div>
    );
  }
}
Countdown.propTypes = {
  date: PropTypes.string.isRequired
};
Countdown.defaultProps = {
  date: new Date()
};





class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paidFor: 0,
      loaded: 0,
      value:'Initial Public Offering',
      data:[]
    };
  }


  async componentDidMount() {
    fetch(proxyUrl + heroku_api)
      .then(blob => blob.json())
      .then(data => {
        console.log(data);
        this.setState({
          data
        });
        console.log(this.state)

        return data;
      })
      .catch(e => {
        console.log(e);
        return e;
      });
  }

  render() {
    return (
    <>
        <div id="home_pg">
            <div id="twothird">
              <div className="main_menu">
                <ul>
                  <li>Initial Public Offering
                  <div className="right_menu">
                        <li><a href="#/">shop</a></li>
                        <li><a href="#/about">about</a></li>
                        <li><a href="#/archive">archive</a></li>
                      </div>
                      </li>
                </ul>
            </div>
              <p>Final Sale: November 25th, 2019</p>
              {/*

              <Countdown date="2019-12-13T12:00:00.000Z"/>
              <div id="offering">
                <img src="../assets/ipo_round2.png"/>
                <div id="caption">
                  <div id="limited">
                    <div className="column-center"><img src="../assets/clock.png"/>Limited Time<br/> Remaining</div>
                    <div className="column-left"><img src="../assets/fire.png"/>Online Only</div>
                    <div className="column-right star"><img src="../assets/45star.png"/>89<br/> Ratings</div>
                  </div>
                  <p>
                  <span id="title">Trevor 224</span><br/>
                  oil, acrylic, spray paint on cotton rag paper<br/>
                  42x90 inches<br/>
                  2019<br/><br/>
                  Original Work on Paper<br/>
                  </p>
                </div>
              </div>

               */}

               {/*
              <div className="clear"></div>
              <div id="line"></div>
              <div id="shares_img">
                <img src="../assets/severed_plain2.png"/>
              </div>
              <h2>Available Shares</h2>
               */}
            </div>

            {/*
            <div id="onethird">
              <div id="product_section">
                <ul>
                  <OrderList state={this.state}/>
                </ul>
              </div>
            </div>
             */}

        </div>
      </>
    );
  }
}

 

export default App;