import React, { Component, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
const chair = "../assets/severed_plain2.png"
const heroku_api = 'https://ipo-paintings.herokuapp.com/media'
const proxyUrl = "https://guarded-everglades-26033.herokuapp.com/"




const postToHeroku = (clicked_sku) => {
  if(clicked_sku) {
      fetch(heroku_api+'/'+clicked_sku, {
        method: 'PUT',
        headers: {'Accept': 'application/json','Content-Type': 'application/json',},
        body: JSON.stringify({sku:clicked_sku,stock:"sold"})
      })
      .then(res => res.text()) // OR res.json()
      .then(res => console.log(res))
  }
}


function Product({product}) {
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef();

  useEffect(() => {
    var iframes = document.querySelectorAll('iframe');
    for (var i = 0; i < iframes.length; i++) {
        iframes[i].parentNode.removeChild(iframes[i]);
    }
    console.log(product)
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: product.description,
                  amount: {
                    currency_code: 'USD',
                    value: '25.63',
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            setPaidFor(true);
            postToHeroku(product.sku)
            console.log(order);
          },
          onError: err => {
            setError(err);
            console.error(err);
          },
        })
        .render(paypalRef.current);
   
  }, [product.description]);

  if (paidFor) {
    return (
      <div>
        <h1>Congrats, you just bought a Share!</h1>
      </div>
    );
  }

  return (
    <div>
      {error && <div>Uh oh, an error occurred! {error.message}</div>}
      <div id="paypal_container">
        <div ref={paypalRef} />
      </div>
      <img src="../assets/severed_plain2.png" />
    </div>
  );
}

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
    }
  }
  componentDidMount() {
    var el = document.getElementById('paypal_container');
    // el.removeChild()
    console.log("data", this.props.location.data);
    var selected_item = this.props.location.data
    this.setState({data:selected_item});
  }
  render() {
  console.log(this.state.data)
    return (
      <div id="order_pg" className="pg_padding">
          <div className="main_menu">
              <ul>
                <li>Initial Public Offering
                    <div className="right_menu">
                      <li><a href="#/">shop</a></li>
                      <li><a href="#/about">about</a></li>
                      <li><a href="#/history">history</a></li>
                    </div>
                </li>
              </ul>
            </div>
        <div className="page_container">
          <div className="order_content">
            <h2>Share #{this.state.data.section} for $25.63</h2>
            <Product product={this.state.data}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Order;