import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import './App.css';
import { Route, Link, BrowserRouter as Router} from "react-router-dom";
import { HashRouter } from 'react-router-dom'
import App from "./App";
import About from "./about";
import Thanks from "./thanks";
import Archive from "./archive";
import Order from "./order";


const heroku_api = 'https://ipo-paintings.herokuapp.com/media'
const proxyUrl = "https://cors-anywhere.herokuapp.com/"

const postToHeroku = (clicked_sku) => {
	if(clicked_sku) {
	    fetch(proxyUrl+ heroku_api+'/'+clicked_sku, {
	      method: 'PUT',
	      headers: {'Accept': 'application/json','Content-Type': 'application/json',},
	      body: JSON.stringify({sku:clicked_sku,stock:"sold"})
	    })
	    .then(res => res.text()) // OR res.json()
	    .then(res => console.log(res))
	}
}

function updateStock() {
  	var vars = {};
	var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
	    vars[key] = value;
	});
	console.log(vars.sku)
	var clicked_sku = vars.sku
	postToHeroku(clicked_sku)
};

const routing = (
  <HashRouter>
		<div className="spacer">&nbsp;</div>
      	<Route exact path="/" component={App} />
      	<Route path="/about" component={About} />
      	<Route path="/archive" component={Archive} />
      	<Route path="/order" component={Order} />
      	<Route path="/thanks" component={Thanks} onEnter={updateStock()}/>
  </HashRouter>
)

ReactDOM.render(routing, document.getElementById('root'))