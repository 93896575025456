import React from 'react'
import { TinyLetter } from 'react-tinyletter';


class Thanks extends React.Component {
  render() {
    return (
    	<div id="thanks_pg" className="pg_padding">
    		<div className="page_container">
	    		<p>
					Thank you for your purchase! You will recieve the section of your painting two weeks after the listed date.<br/>
	    		</p>
	    		<div id="tiny_letter_section">
		    		<p></p>
					<p className="fine_print">subscribe to recieve an update when a new painting is featured</p>
					<TinyLetter list="angiefromspace" id="TinyLetter">
					  <input id="tiny_email" type="email"/>
					  <input id="tiny_submit" type="submit" value="Subscribe"/>
					</TinyLetter>
				</div>

	    	</div>
    	</div>
    )
  }
}
export default Thanks;