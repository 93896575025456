import React from 'react'
import { TinyLetter } from 'react-tinyletter';


class About extends React.Component {
  render() {
    return (
    	<div id="about_pg" className="pg_padding">
    	    <div className="main_menu">
              <ul>
                <li>Initial Public Offering
                <div className="right_menu">
                      <li><a href="#/">shop</a></li>
                      <li><a href="#/about">about</a></li>
                      <li><a href="#/archive">archive</a></li>
                    </div>
                    </li>
              </ul>
            </div>
    		<div className="page_container">
	    		<p>
	    			Initial Public Offering seeks a direct listing approach to a featured, original painting created by the artist. 75% of the painting is available to the public which will be distributed through 9 shares. The painting has been divided into the 9 available shares through an unbiased algorithmic process. The price of each share, $25.63, has then been determined by the overall cost of materials to make the original work. The offering will be open until the clock runs out, which is set for two weeks. When the clock zeros, the featured painting will be severed into the pre-determined shares shown on the homepage.
	    			<br/>
	    			<br/>		
	    			The current painting will be available on a determined date listed on this site. Directly following this date, the artwork will be severed during a live stream instagram feed and the sold shares will be shipped to the respected investors.
					<br/>
					<br/>
					Please allow up to two weeks for delivery.
					<br/>
					<br/>
					Thank you,<br/>
					IPO
	    		</p>
	    		<div id="tiny_letter_section">
		    		<TinyLetter list="angiefromspace" id="TinyLetter">
					  <input id="tiny_email" type="email"/>
					  <input id="tiny_submit" type="submit" value="Subscribe"/>
					</TinyLetter>
					<p className="fine_print">Subscribe for an update when a new painting is featured</p>
				</div>

	    	</div>
    	</div>
    )
  }
}
export default About;